import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import Editable from "./Editable";
import axios from "axios";
import "../css/home.css";

const Home = () => {
  const [contents, setContents] = useState([]);
  const [leadsCounterLoading, setLeadsCounterLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterUserData, setFilterUserData] = useState([]);
  const [editContentId, setEditContentId] = useState(null);
  const [PageLoading, setPageLoading] = useState(false);
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };
  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });

  const getUserData = async () => {
    setPageLoading(true);
    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/dashboard/pending.php",
    })
      .then((res) => {
        const userDataTable = res.data;
        setContents(userDataTable);
        setFilterUserData(userDataTable);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  // =================get leads count api============
  const [leadsCount, setLeads] = useState({
    totalLeads: 0,
    leadsPending: 0,
    leadsDone: 0,
  });

  const getLeadsCount = async () => {
    setLeadsCounterLoading(false);
    await axios
      .get("http://147.182.207.78/acapi/dashboard/count.php")
      .then((res) => {
        const leadsCounter = res.data;
        leadsCounter.map((ele) => {
          setLeads({
            totalLeads: parseInt(ele.total),
            leadsPending: parseInt(ele.pending),
            leadsDone: parseInt(ele.done),
            leadsProgress: parseInt(ele.progress),
          });
        });
        setLeadsCounterLoading(true);
      })

      .catch((err) => {
        console.log("getLeadsCount api error", err);
      });
  };
  useEffect(() => {
    getLeadsCount();
  }, []);

  // ==========search===========

  useEffect(() => {
    const result = contents.filter((ele) => {
      return (
        ele.customername.toLowerCase().match(search.toLowerCase()) ||
        ele.areaname.toLowerCase().match(search.toLowerCase()) ||
        ele.pincode.toLowerCase().match(search.toLowerCase()) ||
        ele.model.toLowerCase().match(search.toLowerCase()) ||
        ele.phonenumber1.toLowerCase().match(search.toLowerCase()) ||
        ele.phonenumber2.toLowerCase().match(search.toLowerCase()) ||
        ele.technicianname.toLowerCase().match(search.toLowerCase()) ||
        ele.tcrno.toLowerCase().match(search.toLowerCase()) ||
        ele.tcrdate.toLowerCase().match(search.toLowerCase()) ||
        ele.install.toLowerCase().match(search.toLowerCase()) ||
        ele.tcramount.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterUserData(result);
  }, [search, contents]);
  // ==========end search===========

  return (
    <>
      <div className="home_outer_container card-body">
        <div className="home_innder_container">
          <div className="row">
            <div
              className="col-xl-3 col-xxl-4 col-sm-6"
              style={{ height: "200px", position: "relative" }}
            >
              <div className="card bg-primary">
                <div className="card-body">
                  <div className="media align-items-center">
                    <span className="p-3 mr-3 border border-white rounded">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.25 5.75H28.5V2.25C28.5 1.78587 28.3156 1.34075 27.9874 1.01256C27.6593 0.684374 27.2141 0.5 26.75 0.5C26.2859 0.5 25.8407 0.684374 25.5126 1.01256C25.1844 1.34075 25 1.78587 25 2.25V5.75H11V2.25C11 1.78587 10.8156 1.34075 10.4874 1.01256C10.1592 0.684374 9.71413 0.5 9.25 0.5C8.78587 0.5 8.34075 0.684374 8.01256 1.01256C7.68437 1.34075 7.5 1.78587 7.5 2.25V5.75H5.75C4.35761 5.75 3.02226 6.30312 2.03769 7.28769C1.05312 8.27226 0.5 9.60761 0.5 11V12.75H35.5V11C35.5 9.60761 34.9469 8.27226 33.9623 7.28769C32.9777 6.30312 31.6424 5.75 30.25 5.75Z"
                          fill="white"
                        />
                        <path
                          d="M0.5 30.25C0.5 31.6424 1.05312 32.9777 2.03769 33.9623C3.02226 34.9469 4.35761 35.5 5.75 35.5H30.25C31.6424 35.5 32.9777 34.9469 33.9623 33.9623C34.9469 32.9777 35.5 31.6424 35.5 30.25V16.25H0.5V30.25Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body text-right">
                      <p className="fs-18 text-white mb-2">Total Leads</p>
                      <span className="fs-48 text-white font-w600">
                        {leadsCounterLoading ? (
                          leadsCount.totalLeads
                        ) : (
                          <ReactLoading
                            type="spinningBubbles"
                            color="#ffffff"
                            height={"5%"}
                            width={"5%"}
                            className="leadsCounterloadingStyle"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-4 col-sm-6"
              style={{ height: "200px" }}
            >
              <div className="card bg-info">
                <div className="card-body">
                  <div className="media align-items-center">
                    <span className="p-3 mr-3 border border-white rounded">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M38.4998 10.4995H35.0002V38.4999H38.4998C40.4245 38.4999 42 36.9238 42 34.9992V13.9992C42 12.075 40.4245 10.4995 38.4998 10.4995Z"
                          fill="white"
                        />
                        <path
                          d="M27.9998 10.4995V6.9998C27.9998 5.07515 26.4243 3.49963 24.5001 3.49963H17.4998C15.5756 3.49963 14.0001 5.07515 14.0001 6.9998V10.4995H10.5V38.4998H31.5V10.4995H27.9998ZM24.5001 10.4995H17.4998V6.99929H24.5001V10.4995Z"
                          fill="white"
                        />
                        <path
                          d="M3.50017 10.4995C1.57551 10.4995 0 12.075 0 13.9997V34.9997C0 36.9243 1.57551 38.5004 3.50017 38.5004H6.99983V10.4995H3.50017Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body text-right">
                      <p className="fs-18 text-white mb-2">Leads Pending</p>
                      <span className="fs-48 text-white font-w600">
                        {leadsCounterLoading ? (
                          leadsCount.leadsPending + leadsCount.leadsProgress
                        ) : (
                          <ReactLoading
                            type="spinningBubbles"
                            color="#ffffff"
                            height={"5%"}
                            width={"5%"}
                            className="leadsCounterloadingStyle"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-4 col-sm-6"
              style={{ height: "200px" }}
            >
              <div className="card bg-success">
                <div className="card-body">
                  <div className="media align-items-center">
                    <span className="p-3 mr-3 border border-white rounded">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.1811 22.0083C15.065 21.9063 14.7968 21.6695 14.7015 21.5799C12.3755 19.3941 10.8517 15.9712 10.8517 12.1138C10.8517 5.37813 15.4868 0.0410156 21.001 0.0410156C26.5152 0.0410156 31.1503 5.37813 31.1503 12.1138C31.1503 15.9679 29.6292 19.3884 27.3094 21.5778C27.2118 21.6699 26.9384 21.9116 26.8238 22.0125L26.8139 22.1799C26.8789 23.1847 27.554 24.0553 28.5232 24.3626C35.7277 26.641 40.9507 32.0853 41.8276 38.538C41.9483 39.3988 41.6902 40.2696 41.1198 40.9254C40.5495 41.5813 39.723 41.9579 38.8541 41.9579C32.4956 41.9591 9.50672 41.9591 3.14818 41.9591C2.2787 41.9591 1.4518 41.5824 0.881242 40.9263C0.31068 40.2701 0.0523763 39.3989 0.172318 38.5437C1.05145 32.0851 6.27444 26.641 13.4777 24.3628C14.4504 24.0544 15.1263 23.1802 15.1885 22.1722L15.1811 22.0083Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div className="media-body text-right">
                      <p className="fs-18 text-white mb-2">Leads Done</p>
                      <span className="fs-48 text-white font-w600">
                        {leadsCounterLoading ? (
                          leadsCount.leadsDone
                        ) : (
                          <ReactLoading
                            type="spinningBubbles"
                            color="#ffffff"
                            height={"5%"}
                            width={"5%"}
                            className="leadsCounterloadingStyle"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ======================= Data table================= */}

          <div className="card-header leads_header">
            <h4 className="card-title">Pending Leads</h4>
            <div className="search_list">
              <i class="fa fa-search" />
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                name="search"
                // value=""
                placeholder="Search Here...."
                onChange={(e) => setSearch(e.target.value.trim())}
              />
            </div>
          </div>
          {PageLoading ? (
            <ReactLoading
              type="spinningBubbles"
              color="#40189d"
              height={"5%"}
              width={"5%"}
              className="homeloadingStyle"
            />
          ) : (
            <div className="card-body scale-up-center">
              <div
                className="table-responsive"
                style={{
                  width: "108%",
                  marginLeft: "-1.9em",
                }}
              >
                <div
                  id="example_wrapper"
                  className="dataTables_wrapper"
                  style={{ position: "relative", height: "30vh" }}
                >
                  <form>
                    <table
                      id="example"
                      className="display w-100 dataTable my_data_table table table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>Area Name</th>
                          <th>Appoint. Date</th>
                          <th>Technician Name</th>
                          <th>Model</th>
                          <th>Category</th>
                          <th>Mobile No</th>
                          <th>Status</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody className="diynamic_color">
                        {contents.length <= 0 ? (
                          <h5
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "45%",
                            }}
                          >
                            No Data Found
                          </h5>
                        ) : (
                          filterUserData.map((content, index) => (
                            <>
                              {editContentId === content.id ? (
                                <Editable
                                  editFormData={editFormData}
                                  handleCancelClick={handleCancelClick}
                                />
                              ) : (
                                <tr>
                                  <td>{content.customername}</td>
                                  <td>
                                    {content.areaname} - {content.pincode}
                                  </td>
                                  <td>{content.appointmentdate}</td>
                                  <td>{content.technicianname}</td>
                                  <td>{content.model}</td>
                                  <td>{content.category}</td>
                                  <td>{content.phonenumber1}</td>
                                  <td>
                                    <div
                                      className="statusStyle"
                                      style={{
                                        backgroundColor:
                                          content.status === "Cancel"
                                            ? "red"
                                            : content.status === "Done"
                                            ? "green"
                                            : content.status === "Pending"
                                            ? "#D9512C"
                                            : content.status === "Progress"
                                            ? "Orange"
                                            : "",
                                        color: "#fff",
                                        paddingLeft: "0.8em",
                                        paddingRight: "0.8em",
                                        paddingTop: "0.2em",
                                        paddingBottom: "0.2em",
                                      }}
                                    >
                                      {content.status}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        )}
                      </tbody>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
