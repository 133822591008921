import * as React from "react";
import "../css/dashboard.css";
import Nav from "../layouts/nav";
export default function Dashboard() {
  return (
    <>
      <Nav />
    </>
  );
}
