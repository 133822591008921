import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Nav } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import Editable from "./Editable";
import "../css/orderinfo.css";
import ReactLoading from "react-loading";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Button } from "react-bootstrap";
import { format } from "date-fns";

// ==============get user Info=============

const OrderInfo = () => {
  const [contents, setContents] = useState([]);
  const [PageLoading, setPageLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUserData, setFilterUserData] = useState([]);
  const [validationError, setValidationError] = useState({
    pinCodeError: false,
    phonenumber1Error: false,
    phonenumber2Error: false,
  });
  const [downloadDateRange, setDownloadDateRange] = useState("");
  // ================downloadXlsx===========
  const handleStartEndDate = (...args) => {
    setDownloadDateRange(args);
  };

  // ================end downloadXlsx===========

  const getUserData = async () => {
    setPageLoading(true);
    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/lead/",
    })
      .then((res) => {
        const userDataTable = res.data;
        setContents(userDataTable);
        setFilterUserData(userDataTable);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  //Modal box
  const [addCard, setAddCard] = useState(false);

  //Edit start

  const [userData, setUserData] = useState({
    customername: "",
    areaname: "",
    pincode: "",
    model: "",
    category: "",
    dealername: "",
    phonenumber1: "",
    phonenumber2: "",
    callnumber: "",
    appointmentdate: "",
    appremarks: "",
    technicianname: "",
    feedback: "",
    tcrno: "",
    tcrdate: "",
    tcramount: "",
    tcrreceive: "",
    install: "",
    status: "",
    share: "",
    others: "",
    pms1: "",
    pms2: "",
    pms3: "",
    pms4: "",
  });

  const userInfoUpdate = (event, content) => {
    event.preventDefault();
    let ownDate = content.tcrdate;
    let ownAppDate = content.appointmentdate;
    var customDate = ownDate.split("-").reverse().join("-");
    var customAppDate = ownAppDate.split("-").reverse().join("-");
    setAddCard(true);
    setUserData({
      ...content,
      tcrdate: customDate,
      appointmentdate: customAppDate,
    });
  };

  const handleChangeData = (val) => {
    const { name, value } = val.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const dataUpdate = async (e) => {
    console.log("userData", userData);
    e.preventDefault();
    // let ownDate = userData.tcrdate;
    // let ownAppDate = userData.appointmentdate;
    // var customDate = ownDate.split("-").reverse().join("/");
    // var customAppDate = ownAppDate.split("-").reverse().join("/");
    if (userData.phonenumber1.length !== 10) {
      swal("Oops", "Phone Number must Be 10 Digit", "error");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: true,
        phonenumber2Error: false,
      });
    } else if (userData.phonenumber2.length !== 10) {
      swal("Oops", "Phone Number must Be 10 Digit", "error");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: false,
        phonenumber2Error: true,
      });
    } else if (userData.pincode.length !== 6) {
      swal("Oops", "Pincode must Be 6 Digit", "error");
      setValidationError({
        pinCodeError: true,
        phonenumber1Error: false,
        phonenumber2Error: false,
      });
    } else if (
      userData.customername !== "" &&
      userData.areaname !== "" &&
      userData.model !== "" &&
      userData.pincode !== "" &&
      userData.phonenumber1 !== ""
    ) {
      await axios({
        method: "put",
        url: "http://147.182.207.78/acapi/lead/",
        data: {
          id: userData.id,
          customername: userData.customername,
          areaname: userData.areaname,
          pincode: userData.pincode,
          model: userData.model,
          category: userData.category,
          dealername: userData.dealername,
          phonenumber1: userData.phonenumber1,
          phonenumber2: userData.phonenumber2,
          callnumber: userData.callnumber,
          appointmentdate: userData.appointmentdate,
          appremarks: userData.appremarks,
          technicianname: userData.technicianname,
          feedback: userData.feedback,
          tcrno: userData.tcrno,
          tcrdate: userData.tcrdate,
          tcramount: userData.tcramount,
          tcrreceive: userData.tcrreceive,
          install: userData.install,
          share: userData.share,
          others: userData.others,
          status: userData.status,
          pms1: userData.pms1,
          pms2: userData.pms2,
          pms3: userData.pms3,
          pms4: userData.pms4,
        },
      })
        .then((res) => {
          getUserData();
        })
        .catch((err) => {
          console.log("err", err);
        });
      swal("Update Successfull", "You clicked the button!", "success");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: false,
        phonenumber2Error: false,
      });
      setAddCard(false);
    } else {
      swal("Oops", "Something went wrong!", "error");
    }
  };

  //const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [editContentId, setEditContentId] = useState(null);

  // Edit function button click to edit

  // edit  data
  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      name: editFormData.name,
      department: editFormData.department,
      gender: editFormData.gender,
      education: editFormData.education,
      mobile: editFormData.mobile,
      email: editFormData.email,
    };
    const newContents = [...contents];
    const index = contents.findIndex((content) => content.id === editContentId);
    newContents[index] = editedContent;
    setContents(newContents);
    setEditContentId(null);
    // setEditModal(false);
  };
  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  // ==========search===========

  useEffect(() => {
    const result = contents.filter((ele) => {
      return (
        ele.customername.toLowerCase().match(search.toLowerCase()) ||
        ele.areaname.toLowerCase().match(search.toLowerCase()) ||
        ele.pincode.toLowerCase().match(search.toLowerCase()) ||
        ele.model.toLowerCase().match(search.toLowerCase()) ||
        ele.phonenumber1.toLowerCase().match(search.toLowerCase()) ||
        ele.technicianname.toLowerCase().match(search.toLowerCase()) ||
        ele.tcrno.toLowerCase().match(search.toLowerCase()) ||
        ele.tcrdate.toLowerCase().match(search.toLowerCase()) ||
        ele.install.toLowerCase().match(search.toLowerCase()) ||
        ele.tcramount.toLowerCase().match(search.toLowerCase())
      );
    });
    setFilterUserData(result);
  }, [search, contents]);
  // ==========end search===========

  // =================tab view===============
  const tabData = [
    {
      name: "All",
    },
    {
      name: "Done",
    },
    {
      name: "Progress",
    },
    {
      name: "Pending",
    },
    {
      name: "Cancell",
    },
    {
      name: "Cancell",
    },
  ];
  const [dateRangeVisable, setDateRangeVisable] = useState(0);

  const dataFilterFun = (i) => {
    setDateRangeVisable(i);
    if (i === 0) {
      setPageLoading(true);
      axios({
        method: "get",
        url: "http://147.182.207.78/acapi/lead/",
      })
        .then((res) => {
          const userDataTable = res.data;
          setContents(userDataTable);
          setFilterUserData(userDataTable);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 1) {
      setPageLoading(true);
      axios({
        method: "get",
        url: "http://147.182.207.78/acapi/lead/done.php",
      })
        .then((res) => {
          const userDataTable = res.data;
          setContents(userDataTable);
          setFilterUserData(userDataTable);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 2) {
      setPageLoading(true);
      axios({
        method: "get",
        url: "http://147.182.207.78/acapi/lead/progress.php",
      })
        .then((res) => {
          const userDataTable = res.data;
          setContents(userDataTable);
          setFilterUserData(userDataTable);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else if (i === 3) {
      setPageLoading(true);
      axios({
        method: "get",
        url: "http://147.182.207.78/acapi/lead/pending.php",
      })
        .then((res) => {
          const userDataTable = res.data;
          setContents(userDataTable);
          setFilterUserData(userDataTable);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("api error", err);
        });
    } else {
      setPageLoading(true);
      axios({
        method: "get",
        url: "http://147.182.207.78/acapi/lead/cancel.php",
      })
        .then((res) => {
          const userDataTable = res.data;
          setContents(userDataTable);
          setFilterUserData(userDataTable);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("api error", err);
        });
    }
  };

  // =================end tab view===============
  // ==========search===========
  const downloadXlsx = async (e) => {
    const newStartDate = downloadDateRange[0];
    const newEndDate = downloadDateRange[1];

    if (newStartDate !== undefined && newEndDate !== undefined) {
      setPageLoading(true);
      const startDate = format(new Date(newStartDate), "yyyy-MM-dd");
      const endDate = format(new Date(newEndDate), "yyyy-MM-dd");
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      await axios({
        method: "post",
        url: "http://147.182.207.78/acapi/lead/search/",
        data: {
          startdate: startDate,
          enddate: endDate,
        },
      })
        .then((res) => {
          let downloadLink = res.data;
          var newdownloadStatus;
          downloadLink.forEach((ele) => {
            return (newdownloadStatus = ele.status);
          });
          if (newdownloadStatus === "422") {
            swal("Oops", "Data Not Found", "error");
          } else {
            setContents(downloadLink);
            setFilterUserData(downloadLink);
          }
          setPageLoading(false);
        })
        .catch((err) => {
          console.log("download api error", err);
        });
    } else {
      swal("Oops", "Please select date range", "error");
    }
  };
  // ==========end search===========
  return (
    <>
      <div className="order_container">
        <div className="col-12 order_inner_container">
          <Modal className="modal fade" show={addCard} onHide={setAddCard}>
            <div className="update_container" role="document">
              <div className="update_inner_container">
                <form>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Update Info</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setAddCard(false)}
                      data-dismiss="modal"
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Customer Name
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="customername"
                              value={userData.customername}
                              placeholder="Enter Customer Name"
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Area Name
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-email"
                              name="areaname"
                              value={userData.areaname}
                              placeholder="Enter Area Name"
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Status</label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              id="status"
                              name="status"
                              style={{ color: "#000" }}
                              onChange={handleChangeData}
                              value={userData.status}
                            >
                              <option value="">Please select</option>
                              <option value="Pending">Pending</option>
                              <option value="Progress">Progress</option>
                              <option value="Done">Done</option>
                              <option value="Cancel">Cancel</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Dealer Name with Location
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="dealername"
                              placeholder="Dealer Name with Location"
                              value={userData.dealername}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Model</label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="model"
                              value={userData.model}
                              placeholder="Enter Model Number"
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Phone Number 1
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              className="form-control"
                              name="phonenumber1"
                              value={userData.phonenumber1}
                              placeholder="Enter Phone Number 1"
                              onChange={handleChangeData}
                              style={{
                                border: validationError.phonenumber1Error
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Phone Number 2
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              className="form-control"
                              name="phonenumber2"
                              value={userData.phonenumber2}
                              placeholder="Enter Phone Number 2"
                              onChange={handleChangeData}
                              style={{
                                border: validationError.phonenumber2Error
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Appointment Remarks
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-currency"
                              name="appremarks"
                              placeholder="Enter Appointment Remarks"
                              value={userData.appremarks}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Call Number
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-currency"
                              name="callnumber"
                              placeholder="Enter Call Number"
                              value={userData.callnumber}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Appointment Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="date"
                              className="form-control"
                              name="appointmentdate"
                              placeholder="Enter Appointment Date"
                              value={userData.appointmentdate}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Category
                          </label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                              value={userData.category}
                            >
                              <option value="">Please select</option>
                              <option value="Split">Split</option>
                              <option value="Window">Window</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Pincode
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              className="form-control"
                              name="pincode"
                              value={userData.pincode}
                              placeholder="Enter Area Pincode"
                              onChange={handleChangeData}
                              style={{
                                border: validationError.pinCodeError
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Technician Name
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-website"
                              name="technicianname"
                              placeholder="Enter Technician Name"
                              value={userData.technicianname}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            TCR No.
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              id="val-digits"
                              name="tcrno"
                              placeholder="Enter TCR No."
                              value={userData.tcrno}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            TCR Date
                          </label>
                          <div className="contact-name">
                            <input
                              type="date"
                              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                              className="form-control"
                              id="val-number"
                              name="tcrdate"
                              placeholder="Enter TCR Date"
                              value={userData.tcrdate}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            TCR Amount
                          </label>
                          <div className="contact-name">
                            <input
                              type="number"
                              className="form-control"
                              id="val-range"
                              name="tcramount"
                              placeholder="Enter TCR Amount"
                              value={userData.tcramount}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            TCR Receive
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="tcrreceive"
                              placeholder="TCR Receive"
                              value={userData.tcrreceive}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Install
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="install"
                              placeholder="Install"
                              value={userData.install}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Feedback
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="feedback"
                              placeholder="Feedback"
                              value={userData.feedback}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            10% Share
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="share"
                              placeholder="10% Share"
                              value={userData.share}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">PMS 1</label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              id="pms1"
                              name="pms1"
                              onChange={handleChangeData}
                              value={userData.pms1}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">PMS 2</label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              name="pms2"
                              onChange={handleChangeData}
                              value={userData.pms2}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">PMS 3</label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              name="pms3"
                              onChange={handleChangeData}
                              value={userData.pms3}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">PMS 4</label>
                          <div className="contact-name">
                            <select
                              className="form-control"
                              name="pms4"
                              onChange={handleChangeData}
                              value={userData.pms4}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Others</label>
                          <div className="contact-name">
                            <textarea
                              type="text"
                              className="form-control"
                              name="others"
                              placeholder="others......"
                              value={userData.others}
                              onChange={handleChangeData}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                        <div className="form-group mb-3"></div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={dataUpdate}
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      onClick={() => setAddCard(false)}
                      className="btn btn-danger"
                    >
                      {" "}
                      <i className="flaticon-delete-1"></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          <div className="card orderInfoTableContainer">
            <div className="card-header leads_header">
              <h4 className="card-title">All Leads</h4>
              <div className="datePicker">
                {dateRangeVisable === 0 ? (
                  <>
                    <i class="fa fa-arrow-right"></i>
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleStartEndDate}
                    />
                    {/* <Button
                    className="mr-2"
                    style={{ height: "48px" }}
                    variant="primary"
                    onClick={downloadXlsx}
                  >
                    Download
                    <span className="btn-icon-right">
                      <i className="fa fa-download" />
                    </span>
                  </Button> */}
                    <Button
                      className="mr-2"
                      style={{ height: "48px" }}
                      variant="primary"
                      onClick={downloadXlsx}
                    >
                      Search{" "}
                      <span className="btn-icon-right">
                        <i className="fa fa-search" />
                      </span>
                    </Button>
                  </>
                ) : (
                  false
                )}
              </div>
              <div className="search_list">
                <i class="fa fa-search" />
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  name="search"
                  // value=""
                  placeholder="Search Here...."
                  onChange={(e) => setSearch(e.target.value.trim())}
                />
              </div>
            </div>
            <div className="tabContainer">
              <Nav as="ul" className="nav-pills  light" active>
                {tabData.map(
                  (data, i) =>
                    i !== tabData.length - 1 && (
                      <Nav.Item
                        as="li"
                        key={i}
                        onClick={() => dataFilterFun(i)}
                        lassName="nav-pills-item"
                        // style={{
                        //   backgroundColor: i === 0 ? "#e6defa" : "",
                        // }}
                      >
                        <Nav.Link eventKey={data.name.toLowerCase()}>
                          {i === 1
                            ? "Done"
                            : i === 2
                            ? "Progress"
                            : i === 3
                            ? "Pending"
                            : i === 4
                            ? "Cancel"
                            : "All"}
                        </Nav.Link>
                      </Nav.Item>
                    )
                )}
              </Nav>
            </div>
            {PageLoading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#40189d"
                height={"5%"}
                width={"5%"}
                className="loadingStyle"
              />
            ) : (
              <div className="card-body">
                <div className="w-100 table-responsive">
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <form onSubmit={handleEditFormSubmit}>
                      <table
                        id="example"
                        className="display w-100 dataTable my_data_table table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>Customer Name</th>
                            <th>Area Name</th>
                            <th>Appoint. Date</th>
                            <th>Techni. Name</th>
                            <th>Model</th>
                            <th>Category</th>
                            <th>Mobile No</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="diynamic_color">
                          {contents.length <= 0 ? (
                            <h5
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "45%",
                              }}
                            >
                              No Data Found
                            </h5>
                          ) : (
                            filterUserData.map((content, index) => (
                              <>
                                {editContentId === content.id ? (
                                  <Editable
                                    editFormData={editFormData}
                                    handleEditFormChange={handleEditFormChange}
                                    handleCancelClick={handleCancelClick}
                                  />
                                ) : (
                                  <tr>
                                    <td>{content.customername}</td>
                                    <td>
                                      {content.areaname} - {content.pincode}
                                    </td>
                                    <td>{content.appointmentdate}</td>
                                    <td>{content.technicianname}</td>
                                    <td>{content.model}</td>
                                    <td>{content.category}</td>
                                    <td>{content.phonenumber1}</td>
                                    <td>
                                      <div
                                        className="statusStyle"
                                        style={{
                                          backgroundColor:
                                            content.status === "Cancel"
                                              ? "red"
                                              : content.status === "Done"
                                              ? "green"
                                              : content.status === "Pending"
                                              ? "#D9512C"
                                              : content.status === "Progress"
                                              ? "Orange"
                                              : "",
                                          color: "#fff",
                                          paddingLeft: "0.8em",
                                          paddingRight: "0.8em",
                                          paddingTop: "0.2em",
                                          paddingBottom: "0.2em",
                                        }}
                                      >
                                        {content.status}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-primary shadow btn-xs sharp mr-2"
                                          // onClick={() => setAddCard(true)}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-secondary	 shadow btn-xs sharp mr-2"
                                          // onClick={(event) =>
                                          //   handleEditClick(event, content)
                                          // }
                                          onClick={(even) =>
                                            userInfoUpdate(even, content)
                                          }
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))
                          )}
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderInfo;
