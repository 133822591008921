import React, { Fragment, useState } from "react";
import "../css/form.css";
import swal from "sweetalert";
import axios from "axios";

const Form = () => {
  const [validationError, setValidationError] = useState({
    pinCodeError: false,
    phonenumber1Error: false,
    phonenumber2Error: false,
  });
  const [userData, setUserData] = useState({
    customername: "",
    areaname: "",
    pincode: "",
    model: "",
    category: "",
    dealername: "",
    phonenumber1: "",
    phonenumber2: "",
    callnumber: "",
    appointmentdate: "",
    appremarks: "",
    technicianname: "",
    feedback: "",
    tcrno: "",
    tcrdate: "",
    tcramount: "",
    tcrreceive: "",
    install: "",
    share: "",
    others: "",
    status: "",
    pms1: "",
    pms2: "",
    pms3: "",
    pms4: "",
  });
  const handleChangeData = (val) => {
    const { name, value } = val.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const dataSubmuit = async (e) => {
    // let ownDate = userData.tcrdate;
    // var customDate = ownDate.split("-").reverse().join("/");
    e.preventDefault();
    if (userData.phonenumber1.length !== 10) {
      swal("Oops", "Phone Number must Be 10 Digit", "error");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: true,
        phonenumber2Error: false,
      });
    } else if (userData.phonenumber2.length !== 10) {
      swal("Oops", "Phone Number must Be 10 Digit", "error");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: false,
        phonenumber2Error: true,
      });
    } else if (userData.pincode.length !== 6) {
      swal("Oops", "Pincode must Be 6 Digit", "error");
      setValidationError({
        pinCodeError: true,
        phonenumber1Error: false,
        phonenumber2Error: false,
      });
    } else if (
      userData.customername !== "" &&
      userData.areaname !== "" &&
      userData.model !== "" &&
      userData.pincode !== "" &&
      userData.phonenumber1 !== ""
    ) {
      await axios({
        method: "post",
        url: "http://147.182.207.78/acapi/lead/",
        data: {
          customername: userData.customername,
          areaname: userData.areaname,
          pincode: userData.pincode,
          model: userData.model,
          category: userData.category,
          dealername: userData.dealername,
          phonenumber1: userData.phonenumber1,
          phonenumber2: userData.phonenumber2,
          callnumber: userData.callnumber,
          appointmentdate: userData.appointmentdate,
          appremarks: userData.appremarks,
          technicianname: userData.technicianname,
          feedback: userData.feedback,
          tcrno: userData.tcrno,
          tcrdate: userData.tcrdate,
          tcramount: userData.tcramount,
          tcrreceive: userData.tcrreceive,
          install: userData.install,
          share: userData.share,
          others: userData.others,
          status: userData.status,
          pms1: userData.pms1,
          pms2: userData.pms2,
          pms3: userData.pms3,
          pms4: userData.pms4,
        },
      })
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
      swal("Update Successfull", "You clicked the button!", "success");
      setValidationError({
        pinCodeError: false,
        phonenumber1Error: false,
        phonenumber2Error: false,
      });
      setUserData({
        customername: "",
        areaname: "",
        pincode: "",
        model: "",
        category: "",
        dealername: "",
        phonenumber1: "",
        phonenumber2: "",
        callnumber: "",
        appointmentdate: "",
        appremarks: "",
        technicianname: "",
        feedback: "",
        tcrno: "",
        tcrdate: "",
        tcramount: "",
        tcrreceive: "",
        install: "",
        share: "",
        others: "",
        status: "",
        pms1: "",
        pms2: "",
        pms3: "",
        pms4: "",
      });
    } else {
      swal("Oops", "Something went wrong!", "error");
    }
  };
  return (
    <Fragment>
      <div className="form_container">
        <div className="row form_row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Create New Lead</h4>
              </div>
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="form-valide"
                    action="#"
                    method="post"
                    onSubmit={dataSubmuit}
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Customer Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              required
                              type="text"
                              className="form-control"
                              id="val-username"
                              name="customername"
                              value={userData.customername}
                              placeholder="Enter Customer Name"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-email"
                          >
                            Area Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              required
                              type="text"
                              className="form-control"
                              id="val-email"
                              name="areaname"
                              value={userData.areaname}
                              placeholder="Enter Area Name"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-password"
                          >
                            Pincode
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              required
                              type="number"
                              className="form-control"
                              name="pincode"
                              value={userData.pincode}
                              placeholder="Enter Area Pincode"
                              onChange={handleChangeData}
                              style={{
                                border: validationError.pinCodeError
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Model
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              required
                              type="text"
                              className="form-control"
                              name="model"
                              value={userData.model}
                              placeholder="Enter Model Number"
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Phone Number 1<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              required
                              type="number"
                              className="form-control"
                              name="phonenumber1"
                              value={userData.phonenumber1}
                              placeholder="Enter Phone Number 1"
                              onChange={handleChangeData}
                              style={{
                                border: validationError.phonenumber1Error
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-confirm-password"
                          >
                            Phone Number 2<span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="phonenumber2"
                              placeholder="Enter Phone Number 2"
                              value={userData.phonenumber2}
                              onChange={handleChangeData}
                              style={{
                                border: validationError.phonenumber2Error
                                  ? "1px solid red"
                                  : "",
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-currency"
                          >
                            Appointment Remarks
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-currency"
                              name="appremarks"
                              placeholder="Enter Appointment Remarks"
                              value={userData.appremarks}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-currency"
                          >
                            Call Number
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-currency"
                              name="callnumber"
                              placeholder="Enter Call Number"
                              value={userData.callnumber}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-currency"
                          >
                            Appointment Date
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="date"
                              className="form-control"
                              id="val-currency"
                              name="appointmentdate"
                              placeholder="Enter Appointment Date"
                              value={userData.appointmentdate}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-website"
                          >
                            Technician Name
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-website"
                              name="technicianname"
                              placeholder="Enter Technician Name"
                              value={userData.technicianname}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-phoneus"
                          >
                            Feedback
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-phoneus"
                              name="feedback"
                              placeholder="Feedback"
                              value={userData.feedback}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-range"
                          >
                            Install
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              name="install"
                              placeholder="Install"
                              value={userData.install}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Status
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="status"
                              style={{ color: "#000" }}
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Pending">Pending</option>
                              <option value="Progress">Progress</option>
                              <option value="Done">Done</option>
                              <option value="Cancel">Cancel</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-digits"
                          >
                            TCR No. <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="val-digits"
                              name="tcrno"
                              placeholder="Enter TCR No."
                              value={userData.tcrno}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-number"
                          >
                            TCR Date <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="date"
                              className="form-control"
                              id="val-number"
                              name="tcrdate"
                              placeholder="Enter TCR Date"
                              value={userData.tcrdate}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-range"
                          >
                            TCR Amount
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              id="val-range"
                              name="tcramount"
                              placeholder="Enter TCR Amount"
                              value={userData.tcramount}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-range"
                          >
                            TCR Receive
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              name="tcrreceive"
                              placeholder="TCR Receive"
                              value={userData.tcrreceive}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Category
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Split">Split</option>
                              <option value="Window">Window</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-range"
                          >
                            Dealer Name with Location
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              name="dealername"
                              placeholder="Dealer Name with Location"
                              value={userData.dealername}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-range"
                          >
                            10% Share
                            <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <input
                              autoComplete="off"
                              type="number"
                              className="form-control"
                              name="share"
                              placeholder="10% Share"
                              value={userData.share}
                              onChange={handleChangeData}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            PMS 1
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            PMS 2
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            PMS 3
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Breakdown">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            PMS 4
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="category"
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              <option value="Split">Breakdown</option>
                              <option value="Done">Done</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-suggestions"
                          >
                            Others <span className="text-danger"></span>
                          </label>
                          <div className="col-lg-6">
                            <textarea
                              className="form-control"
                              id="val-suggestions"
                              name="others"
                              rows="5"
                              placeholder="others......"
                              value={userData.others}
                              onChange={handleChangeData}
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-lg-8 ml-auto">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
