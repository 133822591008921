/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import "../../css/footer.css";
import "../../css/sidebar.css";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "search-job",
        "appication",
        "profile",
        "companies",
        "statistics",
      ],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
        "post-details",
        "ecom-product-detail",
      ];

    return (
      <div
        className="deznav"
        style={{
          backgroundColor: "#40189D",
        }}
      >
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="/">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">DashBoard</span>
              </Link>
            </li>
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="/form">
                <i className="flaticon-381-controls-3"></i>
                <span className="nav-text">Create Lead</span>
              </Link>
            </li>
            <li className={`${app.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="/orderinfo">
                <i className="flaticon-381-television"></i>
                <span className="nav-text">All Leads</span>
              </Link>
            </li>
            <li className={`${app.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="/download">
                <i className="flaticon-381-download"></i>
                <span className="nav-text">Download</span>
              </Link>
            </li>
          </MM>
          <div className="copyright custom_copyright">
            <p>
              <strong>Ac Service Center</strong> <br />© 2022 All Rights
              Reserved
            </p>
            <p>
              Made with{" "}
              <span
                className={`${
                  this.state.loveEmoji ? "heart heart-blast" : "heart"
                }`}
                onClick={() =>
                  this.setState({ loveEmoji: !this.state.loveEmoji })
                }
              ></span>{" "}
              by GoSoftware
            </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
