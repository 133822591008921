import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/style.css";
import { useSelector } from 'react-redux';
import Dashboard from './components/Dashboard';
import Login from './pages/Login';
import Form from './pages/Form';
import OrderInfo from './components/OrderInfo';
import Home from "../src/components/Home"
import DownloadReport from './components/DownloadReport';

const App = () => {
  const [auth, setAuth] = useState(false)
  const loginAuth = useSelector((state) => state.login)
  useEffect(() => {
    setAuth(loginAuth)
  }, [loginAuth])
  return (
    <>
      {auth ?
        <BrowserRouter>
          <Dashboard />
          <Routes>
            <Route>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/form' element={<Form />} />
              <Route exact path='/orderinfo' element={<OrderInfo />} />
              <Route exact path='/download' element={<DownloadReport />} />
              <Route exact path='/login' element={<Login />} />
            </Route>
          </Routes>
        </BrowserRouter> :
        <BrowserRouter>
          <Routes>
            <Route>
              <Route exact path='/' element={<Login />} />
              <Route exact path='*' element={<Login />} />
            </Route>
          </Routes>
        </BrowserRouter>}
    </>
  );
}

export default App;
