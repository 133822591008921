import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import "../css/orderinfo.css";
import "../css/download.css";
import { RangeDatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import { Await } from "react-router-dom";

// ==============get user Info=============

const DownloadReport = () => {
  const [allAreaName, setAllAreaName] = useState([]);
  const [allDealerName, setDealerName] = useState([]);
  const [allPincode, setAllPincode] = useState([]);
  const [contents, setContents] = useState([]);
  const [PageLoading, setPageLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUserData, setFilterUserData] = useState([]);
  const [downloadDateRange, setDownloadDateRange] = useState("");
  const [validationError, setValidationError] = useState({
    pinCodeError: false,
    phonenumber1Error: false,
    phonenumber2Error: false,
  });

  const getUserData = async () => {
    setPageLoading(true);
    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/lead/",
    })
      .then((res) => {
        const userDataTable = res.data;
        setContents(userDataTable);
        setFilterUserData(userDataTable);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  //Edit start

  const [userData, setUserData] = useState({
    customername: "",
    areaname: "",
    pincode: "",
    model: "",
    category: "",
    dealername: "",
    phonenumber1: "",
    phonenumber2: "",
    callnumber: "",
    appointmentdate: "",
    appremarks: "",
    technicianname: "",
    feedback: "",
    tcrno: "",
    tcrdate: "",
    tcramount: "",
    tcrreceive: "",
    install: "",
    status: "",
    share: "",
    others: "",
  });

  const handleChangeData = (val) => {
    const { name, value } = val.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // ==========search===========
  const getDropdownData = async () => {
    // ===============Dealer Name Api=============

    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/lead/search/dealername.php",
    })
      .then((res) => {
        let delName = res.data;
        setDealerName(delName);
      })
      .catch((err) => {
        console.log("api error", err);
      });

    // =====================Area Name Api================

    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/lead/search/areaname.php",
    })
      .then((res) => {
        let areName = res.data;
        setAllAreaName(areName);
      })
      .catch((err) => {
        console.log("api error", err);
      });

    // =====================Pincode Api================

    await axios({
      method: "get",
      url: "http://147.182.207.78/acapi/lead/search/pincode.php",
    })
      .then((res) => {
        let pin = res.data;
        setAllPincode(pin);
      })
      .catch((err) => {
        console.log("api error", err);
      });
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const handleStartEndDate = (...args) => {
    setDownloadDateRange(args);
  };
  const downloadXlsx = async (e) => {
    const newStartDate = downloadDateRange[0];
    const newEndDate = downloadDateRange[1];

    if (newStartDate !== undefined && newEndDate !== undefined) {
      const startDate = format(new Date(newStartDate), "yyyy-MM-dd");
      const endDate = format(new Date(newEndDate), "yyyy-MM-dd");
      await axios({
        method: "post",
        url: "http://147.182.207.78/acapi/lead/search/download.php",
        data: {
          startdate: startDate,
          enddate: endDate,
          status: userData.status,
          pincode: userData.pincode,
          areaname: userData.areaname,
          dealername: userData.dealername,
        },
      })
        .then((res) => {
          let downloadLink = res.data;
          const newdownloadLink = downloadLink.map((ele) => {
            return ele.link;
          });
          var newdownloadStatus;
          downloadLink.forEach((ele) => {
            return (newdownloadStatus = ele.status);
          });
          if (newdownloadStatus === "422") {
            swal("Oops", "Data Not Found", "error");
          } else {
            window.open(newdownloadLink[0], "_parent");
          }
        })
        .catch((err) => {
          console.log("download api error", err);
        });
    }
  };
  // ==========end search===========

  return (
    <>
      <div className="form_container">
        <div className="row form_row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Filter Leads</h4>
              </div>
              <div className="card-body">
                <div className="form-validation">
                  <form
                    className="form-valide"
                    action="#"
                    method="post"
                    // onSubmit={dataSubmuit}
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-username"
                          >
                            Appointment Date
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            className="col-lg-6"
                            style={{ position: "relative" }}
                          >
                            <i
                              class="fa fa-arrow-right"
                              style={{
                                position: "absolute",
                                top: "30%",
                                left: "55%",
                                zIndex: "9",
                              }}
                            ></i>
                            <RangeDatePicker
                              startText="Start"
                              endText="End"
                              startPlaceholder="Start Date"
                              endPlaceholder="End Date"
                              onChange={handleStartEndDate}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Area Name
                          </label>
                          <div className="col-lg-6">
                            <i className="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="areaname"
                              style={{ color: "#000" }}
                              value={userData.areaname}
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              {allAreaName.map((ele, index) => {
                                return (
                                  <option key={index} value={ele.areaname}>
                                    {ele.areaname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Pincode
                          </label>
                          <div className="col-lg-6">
                            <i class="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="pincode"
                              style={{ color: "#000" }}
                              onChange={handleChangeData}
                              value={userData.pincode}
                            >
                              <option value="">Please select</option>
                              {allPincode.map((ele, index) => {
                                return (
                                  <option key={index} value={ele.pincode}>
                                    {ele.pincode}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Status
                          </label>
                          <div className="col-lg-6">
                            <i className="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="status"
                              style={{ color: "#000" }}
                              onChange={handleChangeData}
                              value={userData.status}
                            >
                              <option value="">Please select</option>
                              <option value="Pending">Pending</option>
                              <option value="Progress">Progress</option>
                              <option value="Done">Done</option>
                              <option value="Cancel">Cancel</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-4 col-form-label"
                            htmlFor="val-skill"
                          >
                            Dealer Name
                          </label>
                          <div className="col-lg-6">
                            <i className="fa fa-chevron-down"></i>
                            <select
                              className="form-control"
                              id="category"
                              name="status"
                              style={{ color: "#000" }}
                              onChange={handleChangeData}
                            >
                              <option value="">Please select</option>
                              {allDealerName.map((ele, index) => {
                                return (
                                  <option key={index} value={ele.dealername}>
                                    {ele.dealername}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-8 ml-auto">
                            <Button
                              className="mr-2"
                              style={{ height: "48px" }}
                              variant="primary"
                              onClick={downloadXlsx}
                            >
                              Download{" "}
                              <span className="btn-icon-right">
                                <i className="fa fa-download" />
                              </span>
                            </Button>
                            <Button
                              className="mr-2"
                              style={{
                                height: "48px",
                              }}
                              variant="warning"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadReport;
